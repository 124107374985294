export default {
  title: 'تنظیمات',

  importantData: {
    balance: 'موجودی',
    employeeCount: 'تعداد کارمندان'
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeLogo: 'حذف لوگو',
    uploadLogo: 'بارگزاری لوگو'
  },

  companyInfo: {
    title: 'اطلاعات شرکت',

    labels: {
      generalInfo: 'اطلاعات عمومی',
      telInfo: 'اطلاعات تماس',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      country: 'کشور',
      province: 'استان',
      city: 'شهر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      whatsAppSupport: 'پشتیبانی واتساپ',
      managerPhoneNumber: 'شماره تماس مدیر'
    },

    description: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
      telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
      financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
    },

    validators: {
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
      name: 'نام وارد شده معتبر نمیباشد'
    },

    notifications: {
      logo: {
        upload: {
          success: 'لوگو با موفقیت آپلود شد'
        },
        delete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت بروز شد'
      }
    }
  },

  accessGroup: {
    title: 'گروه های کاربری',

    notifications: {
      insert: {
        success: 'گروه کاربری با موفقیت اضافه شد',
        error: 'افزودن گروه کاربری با خطا همراه شد'
      },

      edit: {
        success: 'گروه کاربری با موفقیت ویرایش شد',
        error: 'ویرایش گروه کاربری با خطا همراه شد'
      },

      delete: {
        success: 'گروه کاربری با موفقیت حذف شد',
        error: 'گروه کاربری مورد نظر یافت نشد.',
        cantDelete: 'گروه کاربری مورد نظر به دلیل داشتن زیر گروه, قابل حذف نمی باشد.'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف گروه کاربری',
        body: 'آیا از حذف  {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن گروه کاربری جدید',

      labels: {
        name: 'عنوان گروه کاربری ',
        allowedActiveSession: 'تعداد نشست های فعال مجاز',
        access: 'دسترسی',
        insert: 'افزودن',
        inMenu: 'منو',

        accessList: {
          manager: 'دسترسی مدیرکل',

          users: {
            totalAccess: 'دسترسی کامل اشخاص',
            show: 'مشاهده اشخاص',
            insert: 'افزودن شخص',
            update: 'ویرایش کامل',
            delete: 'حذف موقت  شخص',
            setRole: 'اختصاص نقش به شخص'
          },

          roles: 'دسترسی کامل نقش ها',
          treasury: {
            title: 'دسترسی کامل خزانه داری',

            bank: 'دسترسی کامل بانک ها',
            cash: 'دسترسی کامل صندوق ها'
          },

          reports: {
            title: 'دسترسی کامل گزارشات',
            logs: 'دسترسی لاگ ها'
          },

          delete: {
            title: 'حذف دائم',

            user: 'اشخاص',
            banks: 'بانک ها',
            cash: 'صندوق ها'
          }
        }
      },

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    edit: {
      title: 'ویرایش گروه کاربری',

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    insertUserToAccessGroup: {

      notifications: {
        success: 'گروه دسترسی شخص مورد با موفقیت بروزسانی شد',
        error: 'بروزرسانی گروه دسترسی شخص مورد نظر با خطا همراه شد',
        noUserSelected: 'هیچ شخصی انتخاب نشده است'
      }
    },

    table: {

      header: {
        row: 'ردیف',
        name: 'عنوان دسترسی'
      }
    },

    actions: {
      insert: 'افزودن',
      save: 'ثبت'
    }
  },

  users: {
    title: 'تنظیمات اشخاص',

    labels: {
      gender: 'اجباری بودن جنسیت'
    }
  },

  security: {
    title: 'امنیت',

    labels: {
      disActive: 'مدت زمان خروج اجباری در صورت نداشتن فعالیت',
      minute: 'دقیقه'
    }
  },

  customize: {
    title: 'شخصی سازی سامانه',

    labels: {
      theme: 'انتخاب تم مورد نظر'
    }
  },

  financial: {
    title: 'مالی',

    rule: 'قوانین',
    tax: 'مالیات',
    NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

    labels: {
      purchaseTax: 'درصد مالیات خرید',
      saleTax: 'درصد مالیات فروش',
      vat: 'درصد مالیات ارزش افزوده'
    },

    notifications: {
      negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات مالی با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات مالی با خطا همراه شد'
      }
    }
  },

  shipping: {
    title: 'حمل و نقل',

    labels: {
      cost: 'هزینه حمل و نقل'
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات حمل و نقل با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات حمل و نقل با خطا همراه شد'
      }
    }
  },

  print: {
    title: 'پرینت',

    labels: {
      companyName: 'نام شرکت',
      QR: 'QR',
      theme: 'قالب رسید پستی'
    },

    themeOptions: {
      theme1: 'A4 عمودی',
      theme2: 'A5 عمودی'
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات پرینت با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات پرینت با خطا همراه شد'
      }
    }
  },

  financialYear: {
    title: 'سال مالی',

    statusTypes: {
      all: 'همه',
      active: 'فعال',
      disActive: 'غیر فعال'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        status: 'وضعیت',
        show: 'مشاهده'
      }
    },

    notifications: {
      isActived: 'سال مالی مورد نظر قبلا فعال شده است',

      insert: {
        success: 'سال مالی جدید با موفقیت ثبت شد',
        error: 'ایجاد سال مالی جدید با خطا همراه شد'
      },

      edit: {
        success: 'ویرایش سال مالی با موفقیت انجام شد',
        error: 'ویرایش سال مالی با خطا همراه شد'
      },

      switch: {
        success: 'مشاهده سال مالی با موفقیت انجام شد',
        error: 'مشاهده سال مالی مورد نظر با خطا همراه شد'
      },

      parseError: {
        period: 'بازه زمانی سال مالی انتخاب شده معتبر نیست',
        paymentGateways: 'موجودی تعدادی از درگاه های پرداخت اعلام وصول نشده است!',
        storeroom: 'تعدادی از حواله های انبار تایید نشده اند!',
        periodNotEnded: 'سال مالی فعلی به اتمام نرسیده است!'
      }
    },

    confirmations: {
      insert: {
        title: 'تاییدیه ایجاد سال مالی جدید',
        body: 'آیا از ایجاد سال مالی جدید اطمینان دارید؟'
      },

      show: {
        title: 'تاییدیه مشاهده سال مالی',
        body: 'آیا از مشاهده سال مالی {name} اطمینان دارید؟'
      }
    },

    edit: {
      title: 'پیکره بندی سال مالی',

      periodTypes: {
        3: '3 ماهه',
        6: '6 ماهه',
        12: '1 ساله'
      },

      labels: {
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        period: 'مدت بازه سال مالی'
      }
    }
  },

  message: {
    title: 'پنل پیامکی',

    labels: {
      messangerKey: 'کد احراز هویت',
      templates: 'قالب ها',
      login: 'قالب پیامک لاگین',
      signup: 'قالب پیامک ثبت نام',
      confirmInfo: 'قالب پیامک تایید اطلاعات'
    },

    notifications: {
      edit: {
        success: 'اطلاعات پنل پیامکی باشگاه با موفقیت بروز شد'
      }
    },

    invalidTexts: {
      onlyEnglish: 'فقط حروف انگلیسی مجاز است!'
    }
  },

  General: {
    title: 'عمومی',
    logo: {
      rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت'
    },
    club: {
      code: 'کد شرکت',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      managerPhoneNumber: 'شماره تماس مدیر'
    }
  },

  Treasury: {
    title: 'خزانه',
    rule: 'قوانین',
    NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

    notifications: {
      negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',

      edit: {
        success: 'اطلاعات خزانه با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات خزانه با خطا همراه شد'
      }
    }
  },

  Locale: {
    title: 'زبان ها',
    language: 'زبان',
    basicSetting: 'تنظیمات پایه',

    labels: {
      basicSetting: 'تنظیمات پایه',
      defaultLanguage: 'زبان پیشفرض شرکت',
      defaultCurrency: 'واحد پولی پیشفرض شرکت',
      region: 'منطقه پیش فرض شرکت',
      defaultCountryCode: 'پیش شماره پیشفرض شرکت'
    },

    currency: {
      IRT: 'تومان',
      IRR: 'ریال',
      USD: 'دلار',
      AED: 'درهم',
      other: 'سایر'
    },

    notifications: {
      edit: {
        success: 'اطلاعات زبان با موفقیت به روز شد.',
        error: 'بروزرسانی اطلاعات زبان با خطا همراه شد.'
      }
    }
  },

  warehouse: {
    title: 'انبارداری',

    labels: {
      checkExitReceiptByRow: 'تایید سطری حواله خروج انبار'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات انبارداری با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات انبارداری با خطا همراه شد.'
      },
      parseError: {
        defaultStoreroomForPersonalSale: 'انبار پیش فرض انتخابی معتبر نیست!'
      }
    }
  },

  purchase: {
    title: 'خرید',

    labels: {
      defaultStoreroom: 'انبار پیش فرض برای خرید',
      hasPrePurchaseInvoiceAge: 'پیش فاکتور خرید پس از مدتی حذف شود.',
      prePurchaseInvoiceAge: 'پیش فاکتور خرید بعد از ... روز حذف شود.',
      hasPreReturnPurchaseInvoiceAge: 'پیش فاکتور مرجوع خرید پس از مدتی حذف شود.',
      preReturnPurchaseInvoiceAge: 'پیش فاکتور مرجوع خرید بعد از ... روز حذف شود.'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات خرید با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات خرید با خطا همراه شد!'
      },

      parseError: {
        preSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور خرید صحیح نیست!',
        preReturnSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور مرجوع خرید صحیح نیست!'
      }
    }
  },

  sale: {
    title: 'فروش',

    labels: {
      defaultStoreroom: 'انبار پیش فرض برای فروش حضوری',
      hasPreSaleInvoiceAge: 'پیش فاکتور فروش پس از مدتی حذف شود.',
      preSaleInvoiceAge: 'پیش فاکتور فروش بعد از ... روز حذف شود.',
      hasPreReturnSaleInvoiceAge: 'پیش فاکتور مرجوع فروش پس از مدتی حذف شود.',
      preReturnSaleInvoiceAge: 'پیش فاکتور مرجوع فروش بعد از ... روز حذف شود.'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات فروش با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات فروش با خطا همراه شد!'
      },

      parseError: {
        preSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور فروش صحیح نیست!',
        preReturnSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور مرجوع فروش صحیح نیست!'
      }
    }
  }
}
