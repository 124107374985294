export default {
  title: 'Treasury',

  banks: {
    title: 'Banks',

    insert: {
      title: 'Add Bank',

      notifications: {
        insert: {
          success: 'New bank information successfully registered',
          error: 'Registering new bank information failed'
        }
      }
    },

    list: {
      title: 'Banks'
    },

    trash: {
      title: 'Banks trash',

      confirmations: {
        delete: {
          title: 'Bank Deletion Confirmation',
          body: 'Are you sure you want to delete {bank} permanently?'
        }
      },

      notifications: {
        restore: {
          success: 'Bank information successfully restored',
          error: 'Retrieving bank information failed'
        },

        delete: {
          success: 'Permanent deletion of bank information completed successfully',
          error: 'Permanent deletion of bank information was accompanied by an error'
        }
      }
    },

    edit: {
      title: 'Edit Bank',
      dynamicTitle: 'Edit Bank {bank}',

      confirmations: {
        delete: {
          title: 'Bank Deletion Confirmation',
          body: 'Are you sure you want to temporarily delete {bank}?'
        }
      },

      notifications: {
        edit: {
          success: 'Bank information successfully edited',
          error: 'Editing bank information failed'
        },

        delete: {
          success: 'Deleting bank information successfully',
          error: 'Deleting bank information was accompanied by an error'
        }
      }
    },

    bank: {
      title: 'Bank',
      dynamicTitle: 'Bank {bank}',

      importantData: {
        balance: 'inventory',
        openingBalance: 'First Period',
        transactionsCount: 'Number of transactions'
      },

      actions: {
        transactions: 'Bank Circulation'
      }
    },

    transactions: {
      title: 'Bank Turnover',
      dynamicTitle: 'Bank Turnover {bank}',

      table: {
        header: {
          balance: 'inventory',
          price: 'Amount',
          description: 'Documents',
          date: 'date',
          docNumber: 'Document number'
        }
      }
    },

    openingBalance: {
      title: '',

      table: {
        header: {
          row: 'row',
          name: 'bank name',
          price: 'Amount',
          delete: 'Delete'
        }
      },

      notifications: {
        locked: 'is the first period of the lock',

        isExisted: {
          message: 'This value is in the list',
          dynamicMessage: 'Bank {bank} is on the list'
        },

        insert: {
          success: 'The first period of the bank was successfully registered',
          error: 'The first registration of the bank period was accompanied by an error'
        },

        edit: {
          success: 'The first period of the bank in question was successfully updated',
          error: 'The first update of the desired bank period was accompanied by an error'
        },

        delete: {
          success: 'The first deletion of the bank in question was successfully completed',
          error: 'The first deletion of the desired bank period was accompanied by an error'
        }
      },

      insert: {
        title: 'The first addition of the bank course'
      },

      edit: {
        title: 'First edition of the bank period {bank}',

        confirmations: {
          delete: {
            title: 'Delete Confirmation',
            body: 'Are you sure you want to delete the first bank term?'
          }
        },

        labels: {
          price: 'Amount',
          delete: 'Delete first course'
        }
      }
    },

    table: {
      header: {
        select: 'Select',
        row: 'row',
        name: 'name',
        cardNumber: 'card number',
        accountNumber: 'account number',
        balance: 'inventory',
        delete: 'delete',
        restore: 'restore'
      }
    },

    labels: {
      name: 'bank name',
      accountNumber: 'account number',
      cardNumber: 'card number',
      shebaNumber: 'Number of Sheba',
      delete: 'Delete Bank'
    },

    validators: {
      name: 'Bank name is not entered correctly',
      accountNumber: 'Account number is not entered correctly',
      cardNumber: 'Card number is not entered correctly',
      shebaNumber: 'Sheba number is not entered correctly'
    }
  },

  paymentGateways: {
    title: 'Payment gateways',

    labels: {
      status: 'Status',
      type: 'type',
      bank: 'clearing bank',
      title: 'Portal Title',
      endTime: 'Ports working day end time',
      merchantCode: 'acceptor code',
      terminalCode: 'terminal code',
      description: 'Description',
      provider: 'Provider',
      delete: 'Delete payment gateway'
    },

    table: {
      header: {
        restore: 'Restore',
        delete: 'delete',
        balance: 'inventory',
        bank: 'clearing bank',
        status: 'Status',
        title: 'Portal Title',
        type: 'port type',
        row: 'row'
      }
    },

    types: {
      all: 'All',
      pos: 'POS',
      online: 'Internet payment gateway'
    },

    status: {
      all: 'All',
      enable: 'enabled',
      disable: 'inactive'
    },

    confirmations: {
      delete: {
        title: 'Confirmation of payment gateway removal',
        body: 'Are you sure you want to delete {paymentGateway} permanently?',
        body1: 'Are you sure you want to delete {paymentGateway} temporarily?'
      }
    },

    notifications: {
      incorrectData: 'The information was not entered correctly',

      insert: {
        success: 'Payment gateway created successfully',
        error: 'There was an error creating the payment gateway'
      },

      edit: {
        success: 'Payment gateway successfully updated',
        error: 'Payment gateway update encountered error'
      },

      delete: {
        success: 'Payment gateway successfully deleted',
        error: 'Deleting the payment gateway was accompanied by an error'
      },

      restore: {
        success: 'Payment gateway successfully restored',
        error: 'There was an error returning the payment gateway'
      }
    },

    insert: {
      title: 'Add payment gateway',
      suggestTitle: '{type} Bank {bank}'
    },

    list: {
      title: 'Payment gateways'
    },

    trash: {
      title: 'Payment Garbage Trash'
    },

    edit: {
      title: 'Edit Payment Gateway',
      dynamicTitle: 'Edit {paymentGateway}'
    },

    paymentGateway: {
      title: 'Payment gateway',
      dynamicTitle: '{paymentGateway}',

      importantData: {
        type: 'type',
        bank: 'clearing bank',
        balance: 'Inventory'
      },

      actions: {
        transactions: 'financial turnover',
        receive: 'Receipt notification'
      }
    },

    transactions: {
      title: 'Payment gateway turnover',
      dynamicTitle: 'Turnover {paymentGateway}',

      table: {
        header: {
          balance: 'inventory',
          price: 'Amount',
          description: 'Documents',
          date: 'date',
          docNumber: 'Document number'
        }
      }
    },

    receive: {
      title: 'Notice of receipt of payment gateway',
      dynamicTitle: 'Receipt Receipt {paymentGateway}',

      labels: {
        receivedBalance: 'amount received',
        hasWage: 'wage',
        wage: 'wage deduction',
        receiveBalanceWithWage: 'Total Price of receipt and commission',
        receivedBalanceTransferId: 'Tracking number',
        totalBalance: 'Total balance',
        receivedYesterdayBalance: 'Total receivable balance'
      },

      notifications: {
        balanceIsNotEnough: 'The amount entered is more than the port balance',
        incorrectPriceOrId: 'The amount or reference id was not entered correctly',
        incorrect_reference_id: 'reference id is not correct',

        receive: {
          success: 'Receipt notified successfully',
          error: 'Receipt notified failed'
        }
      }
    }
  },

  cashBoxes: {
    title: 'Cashes',

    labels: {
      name: 'Name',
      delete: 'Delete'
    },

    confirmations: {
      delete: {
        title: 'Confirm Delete Cash',
        body: 'Are you sure you want to temporarily delete {cashBox} cash?'
      },
      forceDelete: {
        title: 'Confirm Delete Cash',
        body: 'Are you sure you want to delete {cashBox} cash permanently?'
      }
    },

    notifications: {
      insert: {
        success: 'New cash info successfully created',
        error: 'Registering new cash information failed'
      },

      edit: {
        success: 'Cash info successfully updated',
        error: 'Updating new cash information failed'
      },

      delete: {
        success: 'Cash info successfully deleted',
        error: 'Deleting new cash information failed'
      },

      restore: {
        success: 'Cash info successfully restored',
        error: 'Restoring new cash information failed'
      }
    },

    table: {
      header: {
        select: 'Select',
        row: 'Row',
        name: 'Name',
        balance: 'Inventory',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    list: {
      title: 'Cashes'
    },

    trash: {
      title: 'Trashed Cashes'
    },

    insert: {
      title: 'Insert New Cash'
    },

    edit: {
      title: 'Edit Cash',
      dynamicTitle: 'Edit Box {cashBox}'
    },

    cashBox: {
      title: 'Cash',
      dynamicTitle: '{cashBox} Cash',

      importantData: {
        balance: 'Inventory',
        openingBalance: 'First Period',
        transactionsCount: 'Number of Transactions'
      },

      actions: {
        transactions: 'Cash Turnover'
      }
    },

    transactions: {
      title: 'Cash Turnover',
      dynamicTitle: '{cashBox} Cash Turnover',

      table: {
        header: {
          balance: 'Inventory',
          price: 'Amount',
          description: 'Documents',
          date: 'Date',
          docNumber: 'Document Number'
        }
      }
    },

    openingBalance: {

      labels: {
        price: 'Amount',
        delete: 'Delete Opening Balance'
      },

      confirmations: {
        delete: {
          title: 'Delete Confirmation',
          body: 'Are you sure you want to delete the cash opening balance?'
        }
      },

      notifications: {
        locked: 'is the first period of the lock',

        isExisted: {
          message: 'This value is in the list',
          dynamicMessage: 'Box {cashBox} is in the list'
        },

        insert: {
          success: 'The first period of the fund in question was successfully registered',
          error: 'The first registration of the desired fund period was accompanied by an error'
        },

        edit: {
          success: 'The first period of the fund was successfully updated',
          error: 'The first update of the box in question was accompanied by an error'
        },

        delete: {
          success: 'The first fund in question was successfully deleted',
          error: 'The first deletion of the box in question was accompanied by an error'
        }
      },

      table: {
        header: {
          delete: 'delete',
          price: 'Amount',
          name: 'Fund Name',
          row: 'row'
        }
      },

      insert: {
        title: 'Add the first fund course'
      },

      edit: {
        title: 'The first edition of the fund period {cashBox}'
      }
    }
  },

  cost: {
    title: 'Costs',

    paymentTypes: {
      cash: 'receive cash',
      bank: 'Get a card or a card',
      paymentGateway: 'Get a gateway or gateway'
    },

    bankTypes: {
      pos: 'POS',
      card: 'card to card',
      sheba: 'sheba'
    },

    types: {
      cash: 'fund',
      bank: 'bank',
      user: '',
      storeroom: 'store',
      paymentGateway: 'payment gateway'
    },

    labels: {
      all: 'All',
      account: 'Cost headings',
      cash: 'payment from the fund',
      bank: 'Payment from the bank',
      payer: 'payer',
      paymentType: 'payment type',
      price: 'Amount',
      description: 'Description',
      date: 'Date of deposit',
      paymentFrom: 'Payment from',
      maxPayment: 'Maximum payable',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Funds'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Amount',
        account: 'Cost headings',
        payer: 'Payer',
        creator: 'creator',
        date: 'date',
        docNumber: 'Document number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'The entry must be entered as {date}.',
      date: 'The deposit date is not entered correctly!',
      type: 'The payment type is not specified',
      price: 'The entered amount is more than the maximum amount that can be paid!',
      future: 'It is not possible to register a fee for the future'
    },

    insert: {
      title: 'New cost',

      notifications: {
        invalidAccount: 'Expenditure heading not selected',
        invalidCash: 'Inbox not selected',
        invalidBank: 'Bank not selected',
        invalidPrice: 'Amount not entered',
        invalidPaymentType: 'Payment type not specified',
        invalidDescription: 'Expense description not entered',
        lowBalance: 'The balance of the bank or fund is not enough!',
        incorrect_reference_id: 'reference id is not correct',

        insert: {
          success: 'Expense document created successfully',
          error: 'Creating a cost document was associated with an error'
        }
      },

      suggests: {
        description: 'Received from {user} for {subject}'
      }
    },

    list: {
      title: 'Cost list'
    },

    document: {
      title: 'Cost document',
      dynamicTitle: 'Expense document number {docNumber}',

      confirmations: {
        delete: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Expense document information successfully deleted',
          error: 'Deleting the cost document information was accompanied by an error'
        }
      },

      labels: {
        payer: 'Payer / payers',
        account: 'Cost headings',
        details: 'Other information',
        creator: 'creator',
        date: 'Registration date',
        description: 'Description'
      }
    }
  },

  payment: {
    title: 'Payments',

    bankTypes: {
      pos: 'POS',
      card: 'card to card',
      sheba: 'sheba'
    },

    types: {
      cash: 'fund',
      bank: 'bank',
      user: '',
      storeroom: 'store',
      paymentGateway: 'payment gateway'
    },

    paymentTypes: {
      customer: 'Payment to the customer',
      supplier: 'Payment to the supplier'
    },

    labels: {
      all: 'All',
      type: 'Type',
      paymentType: 'payment type',
      date: 'date of payment',
      user: 'Pay to',
      source: 'Payment from',
      price: 'Amount',
      description: 'Description',
      transactionsReferenceId: 'Tracking number',
      maxPayment: 'Maximum payable',
      dynamicBalance: 'credit {balance}',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Funds'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Amount',
        destination: 'Recipient',
        source: 'Payer',
        description: 'Documents',
        creator: 'creator',
        date: 'date',
        docNumber: 'Document number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'The entry must be entered as {date}.',
      date: 'The payment date is not entered correctly!',
      future: 'It is not possible to register a payment for the future'
    },

    insert: {
      title: 'New Payment',

      notifications: {
        invalidUser: 'Person not selected',
        invalidPrice: 'Amount not entered',
        invalidPaymentFrom: 'Payment source not selected',
        invalidPaymentType: 'Payment type not specified',
        invalidDescription: 'Payment Description has not been entered',
        price: 'The entered amount is more than the maximum amount that can be paid!',
        lowBalance: 'The balance of the bank or fund is not enough!',
        incorrect_reference_id: 'reference id is not correct',

        insert: {
          success: 'Payment document created successfully',
          error: 'Creating a payment document with an error'
        }
      },

      suggests: {
        description: 'Received from {user} for {subject}'
      }
    },

    list: {
      title: 'Payroll'
    },

    document: {
      title: 'Payment document',
      dynamicTitle: 'Payment document number {docNumber}',

      confirmations: {
        delete: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Payment document information successfully deleted',
          error: 'Deleting payment document information failed'
        }
      },

      labels: {
        sources: 'Payer / payers',
        destinations: 'Recipient / recipients',
        details: 'Other information',
        creator: 'creator',
        date: 'Registration date',
        description: 'Description'
      }
    }
  },

  receive: {
    title: 'Receives',

    statusTypes: {
      all: 'All',
      confirm: 'Confirmed',
      cancel: 'Canceled'
    },

    receiveTypes: {
      cash: 'receive cash',
      bank: 'Get a card or a card',
      paymentGateway: 'Get a gateway or gateway'
    },

    types: {
      cash: 'fund',
      bank: 'bank',
      user: '',
      storeroom: 'Store',
      paymentGateway: 'payment gateway'
    },

    labels: {
      all: 'All',
      receiveType: 'Receipt type',
      user: 'Receive from',
      cash: 'deposit to fund',
      bank: 'Deposit to bank',
      paymentGateway: 'Deposit to gateway',
      price: 'Amount',
      date: 'Date of deposit',
      description: 'Description',
      transactionsReferenceId: 'Tracking number',
      balance: 'Wallet balance'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        status: 'Status',
        price: 'Amount',
        destination: 'Payee',
        source: 'Payer',
        description: 'Documents',
        creator: 'creator',
        date: 'date',
        referenceId: 'Reference id',
        docNumber: 'Document number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'The entry must be entered as {date}.',
      date: 'The receive date is not entered correctly!',
      future: 'It is not possible to register a receive for the future'
    },

    insert: {
      title: 'New Receive',
      dynamicTitle: 'Receive from {name}',

      notifications: {
        invalidUser: 'Person not selected',
        invalidPrice: 'Amount not entered',
        lessPrice: 'The amount entered should not be less than {price} {currency}',
        invalidDescription: 'Receipt description not entered',
        invalidTransactionsReferenceId: 'Incorrect tracking number entered',
        invalidCash: 'Inbox not selected',
        invalidBank: 'Bank not selected',
        invalidPaymentGateway: 'Port not selected',
        incorrect_reference_id: 'reference id is not correct',

        insert: {
          success: 'Receipt document created successfully',
          error: 'Creating a receive document with an error'
        },

        parseError: {
          name: 'The selected name is not valid!',
          price: 'The selected amount is not valid!',
          referenceId: 'The tracking number entered has already been used!',
          paymentGateway: 'The selected payment gateway is not valid!'
        }
      },

      suggests: {
        description: 'Received from {user} for {subject}'
      },

      table: {
        header: {
          row: 'Row',
          name: 'Name',
          price: 'Price'
        }
      }
    },

    list: {
      title: 'Receive List'
    },

    document: {
      title: 'Receipt Document',
      dynamicTitle: 'Receive Document No. {docNumber}',

      confirmations: {
        delete: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Receipt document information successfully deleted',
          error: 'Deleting the received document information was accompanied by an error'
        }
      },

      labels: {
        sources: 'Payer / payers',
        destinations: 'Recipient / recipients',
        details: 'Other information',
        referenceId: 'Reference id',
        creator: 'creator',
        date: 'Registration date',
        description: 'Description'
      }
    }
  },

  internalFundsTransfer: {
    title: 'Internal Transfer',

    types: {
      cash: 'Cash',
      bank: 'Bank',
      user: '',
      storeroom: 'Store',
      paymentGateway: 'Payment Gateway'
    },

    labels: {
      sourceType: 'Receive from',
      source: '{type} source',
      destinationType: 'deposit to',
      destination: '{type} Destination',
      price: 'Amount',
      date: 'Transfer date',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Funds',
      hasWage: 'wage',
      wage: 'wage deduction',
      referenceId: 'Tracking number',
      description: 'Description'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Amount',
        description: 'Documents',
        payee: 'Payee',
        payer: 'Payer',
        creator: 'Creator',
        date: 'Date',
        referenceId: 'Reference id',
        docNumber: 'Document number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'The entry must be entered as {date}.',
      date: 'The transfer date is not entered correctly!',
      future: 'It is not possible to register a transfer for the future'
    },

    insert: {
      title: 'New Treasury Transfer',

      notifications: {
        invalidPrice: 'Amount not entered',
        invalidSourceType: 'Source type not selected',
        invalidSource: 'Source not selected',
        invalidDestinationType: 'Destination type not selected',
        invalidDestination: 'Destination not selected',
        equalSourceAndDestination: 'source and destination cannot be the same',
        lowBalance: 'The balance of the bank or fund is not enough',
        incorrect_reference_id: 'reference id is not correct',

        insert: {
          success: 'Internal transfer document successfully created',
          error: 'The creation of an internal transfer document was accompanied by an error'
        }
      }
    },

    list: {
      title: 'Internal Transfer'
    },

    document: {
      title: 'Internal Transfer Document',
      dynamicTitle: 'Internal Transfer Document No. {docNumber}',

      confirmations: {
        delete: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Treasury transfer document information successfully deleted',
          error: 'Deleting Treasury Transfer Document Information Caused an Error'
        }
      },

      labels: {
        payer: 'Payer / payers',
        destinations: 'Recipient / recipients',
        details: 'Other information',
        referenceId: 'Reference id',
        creator: 'creator',
        date: 'Registration date',
        description: 'Description'
      }
    }
  },

  transactions: {
    title: 'Financial Transactions',

    list: {
      title: 'Financial Transactions'
    },

    insert: {
      title: 'Add transaction'
    },

    transaction: {
      title: 'Transaction'
    }
  }
}
