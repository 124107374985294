export default {
  title: 'خرید',

  labels: {
    id: 'شناسه فاکتور',
    user: 'تامین کننده',
    date: 'تاریخ ثبت',
    status: 'وضعیت',
    fullName: 'نام کامل',
    address: 'آدرس',
    registryDate: 'تاریخ ثبت',
    phoneNumber: 'شماره تماس',
    pre: 'پیش',
    note: 'توضیحات',
    tax: 'مالیات',
    taxPercent: 'درصد مالیات',
    totalDiscount: 'تخفیف کل',
    totalPrice: 'قیمت کل',
    finalPrice: 'قیمت نهایی',
    shipping: 'حمل و نقل'
  },

  placeholders: {
    id: 'اتوماتیک'
  },

  table: {
    header: {
      id: 'شماره فاکتور',
      creator: 'ایجادکننده',
      user: 'تامین کننده',
      date: 'تاریخ',
      finalPrice: 'مبلغ نهایی',
      status: 'وضعیت',
      delete: 'حذف',
      restore: 'بازگردانی'
    }
  },

  lines: {
    table: {
      header: {
        delete: 'حذف',
        storeroom: 'انبار',
        finalPrice: 'مبلغ نهایی',
        totalPrice: 'مبلغ کل',
        tax: 'مالیات',
        unitPrice: 'مبلغ واحد',
        discount: 'تخفیف کل',
        count: 'تعداد',
        variant: 'متغیر',
        name: 'کالا',
        barcode: 'بارکد',
        row: 'ردیف'
      }
    }
  },

  invoices: {
    title: 'فاکتورهای خرید',

    status: {
      all: 'همه',
      preInvoice: 'پیش فاکتور خرید',
      invoice: 'فاکتور خرید',
      receipt: 'رسید انبار'
    },

    prompts: {
      confirm: {
        title: 'تایید افزودن فاکتور خرید',

        actions: {
          payment: 'تایید',
          cancel: 'انصراف'
        }
      }
    },

    notifications: {
      quantity: 'موجودی کالاها کافی نیست!',
      dynamicQuantity: 'موجودی کالای {name} کافی نیست!',

      isExists: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: '{product} در لیست وجود دارد'
      },
      increaseCount: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: 'تعداد {product} اضافه شد'
      },
      productNotFount: 'کالایی با این بارکد پیدا نشد',

      insert: {
        success: 'فاکتور با موفقیت ثبت شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      edit: {
        success: 'فاکتور با موفقیت بروزرسانی شد',
        error: 'بروزرسانی فاکتور با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور خرید با خطا همراه شد'
      },

      restore: {
        success: 'فاکتور با موفقیت بازگردانی شد',
        error: 'بازگردانی فاکتور خرید با خطا همراه شد'
      },

      refreshStatus: {
        success: 'وضعیت فاکتور خرید با موفقیت برگردانده شد',
        error: 'برگرداندن وضعیت فاکتور خرید با خطا همراه شد'
      },

      parseError: {
        user: 'تامین کننده انتخاب نشده است!',
        lines: 'هیچ کالایی انتخاب نشده است!',
        storeroom: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ کالا وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد کالای انتخاب شده معتبر نیست!'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور خرید',
        body: 'در صورت حذف فاکتور خرید تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور خرید',
        body: 'در صورت حذف فاکتور خرید اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      }
    },

    list: {
      title: 'فاکتورهای خرید'
    },

    insert: {
      title: 'افزودن فاکتور خرید',

      table: {

        header: {
          row: 'ردیف',
          code: 'کد',
          product: 'کالا',
          dynamic: 'متغیر',
          number: 'تعداد',
          unitAmount: 'مبلغ واحد',
          amount: 'مبلغ کل',
          store: 'انبار',
          action: '#'
        }
      }
    },

    invoice: {
      title: 'فاکتور خرید',
      dynamicTitle: 'فاکتور خرید شماره {invoiceId}',

      notifications: {
        insertReturnInvoiceError: 'حواله رسید انبار فاکتور خرید ثبت نشده است'
      },

      actions: {
        print: 'پرینت',
        back: 'بازگشت'
      }
    },

    edit: {
      title: 'ویرایش فاکتور خرید',
      dynamicTitle: 'ویرایش فاکتور خرید شماره {invoiceId}'
    },

    trash: {
      title: 'سطل زباله فاکتور خرید'
    },

    suggest: {
      title: 'پیشنهاد خرید',

      table: {
        header: {
          select: 'انتخاب',
          image: 'عکس',
          barcode: 'بارکد',
          code: 'کد',
          category: 'دسته بندی',
          name: 'عنوان',
          variant: 'متغیر',
          totalSold: 'فروش کل',
          saleInventory: 'موجودی'
        }
      }
    },

    refreshStatus: {
      title: 'برگرداندن وضعیت فاکتور خرید',

      labels: {
        description: 'درنظر داشته باشید که در صورت برگرداندن وضعیت فاکتور، کلیه اسناد و حواله های مربوط به هر وضعیت پاک می شود',
        accept: 'تائید',
        cancel: 'انصراف'
      }
    }
  },

  returnInvoices: {
    title: 'فاکتورهای مرجوع خرید',

    status: {
      all: 'همه',
      preInvoice: 'پیش فاکتور مرجوع خرید',
      invoice: 'فاکتور موجوع خرید',
      receipt: 'خروج انبار'
    },

    notifications: {
      noProductSelected: 'هیچ کالایی انتخاب نشده است',
      productsNotExisted: 'موجودی کالاهای انتخاب شده کافی نمی باشد',
      dynamicProductNotExisted: 'موجودی کالای {name} کافی نمی باشد',

      isExists: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: '{product} در لیست وجود دارد'
      },
      increaseCount: {
        message: 'تعداد این کالا اضافه شد',
        dynamicMessage: 'تعداد {product} اضافه شد'
      },
      productNotFount: 'کالایی با این بارکد پیدا نشد',

      insert: {
        success: 'فاکتور مرجوع خرید با موفقیت ثبت شد',
        error: 'ثبت فاکتور مرجوع خرید با خطا همراه شد'
      },

      edit: {
        success: 'فاکتور مرجوع خرید با موفقیت بروزرسانی شد',
        error: 'بروزرسانی فاکتور مرجوع خرید با خطا همراه شد'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور مرجوع خرید با خطا همراه شد'
      },

      restore: {
        success: 'فاکتور با موفقیت بازگردانی شد',
        error: 'بازگردانی فاکتور مرجوع خرید با خطا همراه شد'
      },

      refreshStatus: {
        success: 'وضعیت فاکتور مرجوع خرید با موفقیت برگردانده شد',
        error: 'برگرداندن وضعیت فاکتور مرجوع خرید با خطا همراه شد'
      },

      parseError: {
        user: 'تامین کننده انتخاب نشده است!',
        lines: 'هیچ کالایی انتخاب نشده است!',
        storeroom: 'انبار انتخاب نشده است!',
        unitPrice: 'مبلغ کالا وارد نشده است!',
        invoiceNumber: 'این شماره فاکتور از قبل انتخاب شده است!',
        quantity: 'تعداد کالای انتخاب شده معتبر نیست!'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور مرجوع خرید',
        body: 'در صورت حذف فاکتور مرجوع خرید تمامی اسناد و حواله های انبار مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تائیدیه حذف فاکتور مرجوع خرید',
        body: 'در صورت حذف فاکتور مرجوع خرید اطلاعات فاکتور قابل برگشت نیست. آیا اطمینان دارید؟'
      }
    },

    list: {
      title: 'فاکتورهای مرجوع خرید',

      table: {

        header: {
          number: 'شماره فاکتور',
          creator: 'ایجادکننده',
          user: 'طرف حساب',
          date: 'تاریخ ایجاد',
          totalPrice: 'مبلغ کل',
          status: 'وضعیت'
        }
      }
    },

    insert: {
      title: 'افزودن فاکتور مرجوع خرید',

      table: {

        header: {
          row: 'ردیف',
          code: 'کد',
          product: 'کالا',
          dynamic: 'متغیر',
          number: 'تعداد',
          unitAmount: 'مبلغ واحد',
          amount: 'مبلغ کل',
          store: 'انبار',
          action: '#'
        }
      }
    },

    invoice: {
      title: 'فاکتور مرجوع خرید',
      dynamicTitle: 'فاکتور مرجوع خرید شماره {invoiceId}'
    },

    edit: {
      title: 'ویرایش فاکتور مرجوع خرید',
      dynamicTitle: 'ویرایش فاکتور مرجوع خرید شماره {invoiceId}'
    },

    trash: {
      title: 'سطل زباله فاکتور مرجوع خرید'
    },

    refreshStatus: {
      title: 'برگرداندن وضعیت فاکتور مرجوع خرید',

      labels: {
        description: 'درنظر داشته باشید که در صورت برگرداندن وضعیت فاکتور، کلیه اسناد و حواله های مربوط به هر وضعیت پاک می شود',
        accept: 'تائید',
        cancel: 'انصراف'
      }
    }
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    filters: 'فیلترها'
  }
}
