export default {
  title: 'Purchases',

  labels: {
    id: 'Invoice ID',
    user: 'supplier',
    date: 'Registration date',
    status: 'Status',
    fullName: 'Full Name',
    address: 'Address',
    orderDate: 'Order Date',
    phoneNumber: 'Phone number',
    pre: 'pre',
    note: 'note',
    tax: 'Tax',
    taxPercent: 'Tax percent',
    totalDiscount: 'Total discount',
    totalPrice: 'Total price',
    finalPrice: 'Final price',
    shipping: 'Shipping'
  },

  placeholders: {
    id: 'Automatic'
  },

  table: {
    header: {
      id: 'Invoice number',
      creator: 'creator',
      user: 'Supplier',
      date: 'date',
      finalPrice: 'final amount',
      status: 'Status',
      delete: 'Delete',
      restore: 'Restore'
    }
  },

  lines: {
    table: {
      header: {
        delete: 'Delete',
        storeroom: 'Store',
        finalPrice: 'Final price',
        totalPrice: 'Total',
        tax: 'Tax',
        unitPrice: 'Unit price',
        discount: 'Total discount',
        count: 'QTY',
        variant: 'Variable',
        name: 'Product',
        barcode: 'Barcode',
        row: 'Row'
      }
    }
  },

  invoices: {
    title: 'Purchases',

    status: {
      all: 'All',
      preInvoice: 'Pre-purchase invoice',
      invoice: 'Invoice',
      receipt: 'Receipt warehouse'
    },

    prompts: {
      confirm: {
        title: 'Insert purchase invocie confirmation',

        actions: {
          payment: 'Confirm',
          cancel: 'Cancel'
        }
      }
    },

    notifications: {
      quantity: 'There is not enough service!',
      dynamicQuantity: 'There is not enough service of {name}!',

      isExists: {
        message: 'Number of items added',
        dynamicMessage: 'There are {product} in the list'
      },
      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of {product} added'
      },
      productNotFount: 'No product found with this barcode',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration was incorrect!'
      },

      edit: {
        success: 'Invoice successfully updated',
        error: 'Invoice update encountered error!'
      },

      delete: {
        success: 'The invoice was successfully deleted',
        error: 'Delete purchase invoice has error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore purchase invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Purchase invoice status returned successfully',
        error: 'Reverting invoice status returned failed'
      },

      parseError: {
        user: 'Supplier not selected!',
        lines: 'No items selected!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete purchase invocie confirmation',
        body: 'If the purchase invoice is deleted, all documents and warehouse transfers related to the invoice will be deleted. are you sure?'
      },
    forceDelete: {
      title: 'Confirmation of deletion of purchase invoice',
      body: 'If the purchase invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
    }
    },

    list: {
      title: 'Purchases'
    },

    insert: {
      title: 'Add Purchase Invoice',

      table: {

        header: {
          row: 'row',
          code: 'Code',
          product: 'Kala',
          dynamic: 'variable',
          number: 'Number',
          unitAmount: 'unit amount',
          amount: 'total amount',
          store: 'store',
          action: '#'
        }
      }
    },

    invoice: {
      title: 'Purchase Invoice',
      dynamicTitle: 'Purchase Invoice No. {invoiceId}',

      notifications: {
        insertReturnInvoiceError: 'Invoice in invoice purchase invoice not registered'
      },

      actions: {
        print: 'Print',
        back: 'Back'
      }
    },

    edit: {
      title: 'Edit Purchase Invoice',
      dynamicTitle: 'Edit Purchase Invoice No. {invoiceId}'
    },

    trash: {
      title: 'Purchase invoice trash list'
    },

    suggest: {
      title: 'Suggest purchase',

      table: {
        header: {
          select: 'Select',
          image: 'Image',
          barcode: 'Barcode',
          code: 'Code',
          category: 'Category',
          name: 'Title',
          variant: 'Variant',
          totalSold: 'Total sold',
          saleInventory: 'Sale inventory'
        }
      }
    },

    refreshStatus: {
      title: 'Return Purchase Invoice Status',

      labels: {
        description: 'Note that if the invoice status is returned, all documents and remittances related to each status will be deleted',
        accept: 'Confirm',
        cancel: 'Cancel'
      }
    }
  },

  returnInvoices: {
    title: 'Purchase Refunds',

    status: {
      all: 'All',
      preInvoice: 'Pre-purchase invoice',
      invoice: 'invoice for purchase',
      receipt: 'warehouse exit'
    },

    notifications: {
      noProductSelected: 'No products selected',
      productsNotExisted: 'Inventory of selected goods is not enough',
      dynamicProductNotExisted: 'The inventory of the {name} is not enough',

      isExists: {
        message: 'Number of items added',
        dynamicMessage: 'There are {product} in the list'
      },
      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of {product} added'
      },
      productNotFount: 'No product found with this barcode',

      insert: {
        success: 'Purchase reference invoice successfully registered',
        error: 'The purchase reference invoice registration was accompanied by an error'
      },

      edit: {
        success: 'Purchase reference invoice successfully updated',
        error: 'Updated purchase reference invoice failed'
      },

      delete: {
        success: 'The invoice was successfully deleted',
        error: 'Delete returned purchase invoice has error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore return purchase invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Purchase invoice status returned successfully',
        error: 'Reverting invoice status returned failed'
      },

      parseError: {
        user: 'Supplier not selected!',
        lines: 'No items selected!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete return purchase invoice confirmation',
        body: 'In case of deleting the returned purchase invoice, all documents and warehouse transfers related to the invoice will be deleted. are you sure?'
      },
      forceDelete: {
        title: 'Confirmation of deletion of return purchase invoice',
        body: 'If the return purchase invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      }
    },

    list: {
      title: 'Purchase Refunds',

      table: {

        header: {
          number: 'Invoice number',
          creator: 'creator',
          user: 'Account side',
          date: 'creation date',
          totalPrice: 'total amount',
          status: 'Status'
        }
      }
    },

    insert: {
      title: 'Add Purchase Refunds',

      table: {

        header: {
          row: 'row',
          code: 'Code',
          product: 'Kala',
          dynamic: 'variable',
          number: 'Number',
          unitAmount: 'unit amount',
          amount: 'total amount',
          store: 'store',
          action: '#'
        }
      }
    },

    invoice: {
      title: 'Purchase Reference Invoice',
      dynamicTitle: 'Purchase Reference Invoice No. {invoiceId}'
    },

    edit: {
      title: 'Edit Purchase Invoice',
      dynamicTitle: 'Edit Purchase Invoice {invoiceId}'
    },

    trash: {
      title: 'Return purchase invoice trash list'
    },

    refreshStatus: {
      title: 'Return Purchase Invoice Status',

      labels: {
        description: 'Note that if the invoice status is returned, all documents and remittances related to each status will be deleted',
        accept: 'Confirm',
        cancel: 'Cancel'
      }
    }
  },

  actions: {
    insert: 'Add',
    edit: 'edit',
    filters: 'Filters'
  }
}
