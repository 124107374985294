export default {
  title: 'حسابداری',

  types: {
    title: 'جدول حساب ها',

    labels: {
      name: 'عنوان سرفصل'
    },

    accountsLabel: {
      asset: 'دارایی',
      current_assets: 'دارایی های جاری',
      cash_and_bank_assets: 'موجودی نقد و بانک',
      cash: 'صندوق',
      bank: 'بانک',
      payment_gateways: 'درگاه ها',
      internet_payment_gateway: 'درگاه اینترنتی',
      pos: 'پوز',
      on_way_inventory: 'موجودی در راه',
      warehouses_inventory: 'موجودی انبارها',
      waiting_to_send_inventory: 'موجودی در انتظار ارسال',
      accounts_receivable: 'حساب های دریافتنی',
      inventory: 'موجودی کالا',
      liability: 'بدهی',
      current_liabilities: 'بدهی های جاری',
      accounts_payable: 'حساب های پرداختنی',
      tax_payable: 'مالیات پرداختنی',
      vat: 'مالیات بر ارزش افزوده',
      taxes: 'مالیات بر عملکرد',
      sale_tax: 'مالیات بر فروش',
      purchase_tax: 'مالیات بر خرید',
      equity: 'حقوق صاحبان سهام',
      capital: 'سرمایه',
      opening_capital: 'سرمایه اولیه',
      revenue: 'درآمد',
      other_revenue: 'مراکز درآمد',
      service_revenue: 'درآمد خدمات',
      sale_revenue: 'درآمد فروش',
      personal_sale_revenue: 'فروش حضوری',
      total_personal_sale_revenue: 'فروش کل',
      personal_return_sale_expenses: 'برگشت از فروش',
      personal_sale_discounts_expenses: 'تخفیفات',
      internet_sale_revenue: 'فروش اینترنتی',
      total_internet_sale_revenue: 'فروش کل',
      internet_return_sale_expenses: 'برگشت از فروش',
      internet_sale_discounts_expenses: 'تخفیفات',
      telephone_sale_revenue: 'فروش تلفنی',
      total_telephone_sale_revenue: 'فروش کل',
      telephone_return_sale_expenses: 'برگشت از فروش',
      telephone_sale_discounts_expenses: 'تخفیفات',
      expenses: 'هزینه',
      other_expenses: 'مراکز هزینه',
      cancel_service_expenses: 'هزینه لغو خدمات',
      personnel_expenses: 'هزینه های پرسنلی',
      payroll_expenses: 'هزینه حقوق و دستمزد',
      sale_expenses: 'هزینه فروش',
      sale_shipping_expenses: 'هزینه حمل و نقل',
      purchase_expenses: 'هزینه خرید',
      purchase_shipping_expenses: 'هزینه حمل و نقل',
      financial_expenses: 'هزینه مالی',
      wage_expenses: 'هزینه کارمزد',
      bank_wage_expenses: 'بانک',
      payment_gateway_wage_expenses: 'درگاه',
      purchase: 'خرید کالا',
      total_purchase: 'خرید کل',
      return_purchase_expenses: 'برگشت از خرید',
      purchase_discounts_expenses: 'تخفیفات',
      cost_of_goods_bought: 'ﺑﻬﺎی ﺗﻤﺎم ﺷﺪه ﮐﺎﻻی خریداری شده',
      cost_of_goods_sold: 'ﺑﻬﺎی ﺗﻤﺎم ﺷﺪه ﮐﺎﻻی ﻓﺮوش رﻓﺘﻪ'
    },

    notifications: {
      insert: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'ثبت اطلاعات سرفصل جدید با خطا همراه شد'
      },

      edit: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'بروزرسانی اطلاعات سرفصل با خطا همراه شد'
      },

      delete: {
        success: 'سرفصل با موفقیت حذف شد',
        error: 'حذف سرفصل با خطا همراه شد'
      },

      parseError: {
        parent: 'سرفصل والد انتخاب نشده است',
        name: 'عنوان سرفصل وارد نشده است'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف سرفصل',
        body: 'آیا از حذف سرفصل  {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن سرفصل جدید'
    },

    edit: {
      title: 'ویرایش سرفصل'
    }
  },

  openingBalance: {
    title: 'تراز افتتاحیه',

    labels: {
      assets: 'دارایی',
      liabilities: 'بدهی',
      equity: 'سرمایه'
    },

    accountsLabel: {
      cash_and_bank_assets: 'موجودی نقد و بانک',
      bank: 'بانک',
      cash: 'صندوق',
      warehouse_inventory: 'موجودی کالا',
      accounts_receivable: 'حساب های دریافتنی',
      accounts_payable: 'حساب های پرداختنی',
      capital: 'سرمایه'
    },

    notifications: {
      locked: 'اول دوره قفل می باشد',
      unLocked: 'اول دوره قفل نمی باشد',
      lock: {
        success: 'ترازنامه با موفقیت قفل شد',
        error: 'قفل شدن ترازنامه با خطا مواجه شد'
      },
      unlock: {
        success: 'ترازنامه با موفقیت باز شد',
        error: 'باز شدن ترازنامه با خطا مواجه شد'
      }
    },

    confirmations: {
      unlock: {
        title: 'تائیدیه بازکردن تراز افتتاحیه',
        body: 'آیا از باز کزدن تراز افتتاحیه اطمینان دارید؟'
      }
    }
  },

  documents: {
    title: 'لیست اسناد',

    notifications: {
      delete: {
        success: 'اطلاعات سند مورد نظر با موفقیت حذف شد',
        error: 'حذف اطلاعات سند مورد نظر با موفقیت حذف شد'
      }
    },

    document: {
      title: 'سند',

      table: {
        header: {
          rowNumber: 'ردیف',
          description: 'شرح',
          debt: 'بدهکار',
          credit: 'بستانکار'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'رسید هزینه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          account: 'به سرفصل ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      receive: {
        receiveReceipt: 'رسید دریافت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',
        transactionId: 'شماره تراکنش:',
        paymentConfirm: 'تاییدیه پرداخت:',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'از آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'دریافت گردید'
        }
      },

      payment: {
        paymentReceipt: 'رسید پرداخت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'به آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'رسید انتقالی خزانه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payee: 'به ',
          payed: 'پرداخت گردید'
        }
      }
    },

    list: {
      title: 'لیست اسناد',

      table: {
        header: {
          id: 'شماره سند',
          createdAt: 'تاریخ',
          creator: 'ایجاد کننده',
          description: 'مستندات',
          type: 'نوع'
        },

        filters: {
          type: {
            all: 'همه'
          }
        }
      },

      confirmation: {
        deleteDocument: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      types: {
        manual: 'دستی',
        receive: 'دریافت',
        payment: 'پرداخت',
        withdrawal: 'برداشت',
        internal_fund_transfer: 'انتقال',
        revenue: 'درامد',
        cost: 'هزینه',
        beginning_inventory: 'اول دوره',
        payroll: 'حقوق',
        purchase: 'خرید',
        return_purchase: 'مرجوع خرید',
        sale: 'فروش',
        return_sale: 'مرجوع فروش',
        warehouse_receipt: 'رسید انبار',
        warehouse_requisition: 'خروج انبار',
        inventory_transfer: 'انتقالی انبار'
      }
    },

    insert: {
      title: 'افزودن سند',
      dynamicTitle: 'افزودن سند دریافت/پرداخت',

      labels: {
        documentType: 'نوع سند',
        receive: 'دریافت',
        payment: 'پرداخت',
        paymentTo: 'پرداخت به',
        receiveFrom: 'دریافت از',
        withdrawalFrom: 'برداشت از',
        depositTo: 'واریز به',
        price: 'مبلغ',
        cashBox: 'صندوق',
        bank: 'بانک',
        depositType: 'نوع واریز',
        choose: 'انتخاب کنید',
        pos: 'POS',
        cardToCard: 'کارت به کارت',
        shaba: 'شبا'
      },

      notifications: {

        receive: {
          insert: {
            success: 'سند دریافت با موفقیت ایجاد شد',
            error: 'ایجاد سند دریافت با خطا همراه شد'
          }
        },

        payment: {
          insert: {
            success: 'سند پرداخت با موفقیت ایجاد شد',
            error: 'ایجاد سند پرداخت با خطا همراه شد'
          }
        },

        parseError: {
          user: 'کاربر انتخاب نشده است',
          cashBox: 'صندوق انتخاب نشده است',
          bank: 'بانک انتخاب نشده است',
          price: 'مبلغ وارد نشده است',
          depositTo: 'واریز به بانک یا صندوق مشخص نشده است',
          depositType: 'نوع واریز مشخص نشده است',
          withdrawalFrom: 'برداشت از بانک یا صندوق مشخص نشده است'
        }
      }
    }
  }
}
