export default {
  title: 'Warehousing',

  stores: {
    title: 'Warehouses',

    labels: {
      name: 'Name',
      code: 'Code',
      delete: 'Delete'
    },

    list: {
      title: 'Warehouses',

      table: {
        header: {
          rowNumber: 'row',
          code: 'Code',
          name: 'Warehouse Title',
          balance: 'inventory'
        }
      }
    },

    insert: {
      title: 'Add Warehouse',

      notifications: {
        insert: {
          success: 'New warehouse information successfully registered',
          error: 'Add new warehouse failed'
        }
      }
    },

    edit: {
      title: 'Edit Warehouse',

      notifications: {
        edit: {
          success: 'Warehouse information successfully edited',
          error: 'Editing warehouse information failed'
        }
      }
    },

    store: {
      title: 'Warehouse',

      importantData: {
        code: 'Warehouse ID'
      },

      actions: {
        storeTurnover: 'warehouse turnover',
        storeInventory: 'Browse some available products',
        storeInventoryValuation: 'Review the amount of available products'
      }
    },

    trash: {
      title: 'Warehouse',

      table: {
        header: {
          rowNumber: 'row',
          code: 'Code',
          name: 'Warehouse Title',
          delete: 'delete',
          restore: 'restore'
        }
      },

      confirmations: {
        delete: {
          title: 'Warehouse deletion confirmation',
          body: 'Are you sure you want to delete {name} permanently?'
        }
      },

      notifications: {
        delete: {
          success: 'Warehouse successfully deleted',
          error: 'Deleting warehouse information failed'
        },
        restore: {
          success: 'Warehouse successfully returned',
          error: 'Warehouse restored failed'
        }
      }
    },

    delete: {
      confirmations: {
        delete: {
          title: 'Warehouse deletion confirmation',
          body: 'Are you sure you want to temporarily delete warehouse {name}?'
        }
      },

      notifications: {
        delete: {
          success: 'Warehouse information successfully deleted',
          error: 'Deleting warehouse information failed'
        }
      }
    },

    validators: {
      name: 'Warehouse name not entered correctly',
      nameCantEmpty: 'Warehouse name could not be empty',
      code: 'Warehouse code not entered correctly',
      codeCantEmpty: 'Warehouse code could not be empty'
    }
  },

  turnover: {
    title: 'Warehouse Turnover',
    dynamicTitle: '{name} Turnover',

    table: {
      header: {
        docNumber: 'Document number',
        name: 'Product Title',
        image: 'Product Photo',
        date: 'date',
        barcode: 'Barcode',
        code: 'product code',
        category: 'Category',
        variant: 'variable',
        quantity: 'Number',
        unitPrice: 'unit amount',
        description: 'Documents',
        price: 'Amount',
        balance: 'Inventory',
        status: 'Status'
      }
    },

    statusMapper: {
      sent: 'Posted',
      onWay: 'On way',
      received: 'Receipted',
      waitingToSend: 'Waiting to send'
    }
  },

  inventory: {
    title: 'Inventory',
    dynamicTitle: '{name} Inventory',

    tabs: {
      inventory: 'Inventory',
      turnover: 'Product warehouse turnover'
    },

    table: {
      header: {
        rowNumber: 'row',
        image: 'Product Photo',
        barcode: 'Barcode',
        code: 'product code',
        category: 'Category',
        name: 'Product Title',
        variant: 'variable',
        openingInventory: 'First Course',
        received: 'Reached warehouse',
        minInventory: 'Minimum Inventory',
        totalInventory: 'total inventory',
        sent: 'Posted',
        onWay: 'On the way',
        waitingToSend: 'waiting to send',
        saleInventory: 'Inventory for sale'
      }
    },

    notifications: {
      export: {
        success: 'The request to get the output of browsing some warehouses has been successfully completed, please wait.',
        error: 'The request to receive the output of a number of warehouses was accompanied by an error.'
      }
    }
  },

  inventoryValuation: {
    title: 'Browse Warehouse Amount',
    dynamicTitle: 'Browse Warehouse Amount {name}',

    table: {
      header: {
        rowNumber: 'row',
        image: 'Product Photo',
        barcode: 'Barcode',
        code: 'product code',
        category: 'Category',
        name: 'Product Title',
        variant: 'variable',
        openingInventory: 'First Course',
        received: 'Reached warehouse',
        minInventory: 'Minimum Inventory',
        totalInventory: 'total inventory',
        sent: 'Posted',
        onWay: 'On the way',
        waitingToSend: 'waiting to send',
        saleInventory: 'Inventory for sale'
      }
    }
  },

  receipts: {
    title: 'Remittances',

    list: {
      title: 'Remittances',

      storeName: '{name} warehouse',

      table: {
        header: {
          id: 'Reference number',
          creator: 'creator',
          accountSide: 'account side',
          invoiceId: 'Invoice ID',
          type: 'type',
          approved: 'Status',
          createdAt: 'Registration Date'
        },

        filters: {
          type: {
            all: 'All',
            receive: 'Receipt',
            transfer: 'Transfer',
            exit: 'Requisition'
          },

          approved: {
            all: 'All',
            confirm: 'Confirmed',
            pending: 'pending'
          }
        }
      },

      receiptTypes: {
        receive: 'Receipt',
        transfer: 'Transfer',
        exit: 'Requisition'
      },

      statusTypes: {
        confirm: 'Confirmed',
        pending: 'pending'
      }
    },

    insert: {
      title: 'Add Remittance',

      labels: {
        type: 'Remittance type',
        store: 'Warehouse',
        originStore: 'source warehouse',
        destinationStore: 'destination warehouse',
        registryDate: 'Registration Date',
        insertProduct: 'Add new product',
        first: 'first',
        second: 'second'
      },

      table: {
        header: {
          barcode: 'Barcode',
          name: 'Kala',
          variant: 'variable',
          count: 'count',
          unitPrice: 'unit amount',
          totalPrice: 'total amount',
          storeroom: 'source warehouse',
          destinationStoreroom: 'Destination Warehouse',
          delete: 'Delete'
        }
      },

      receiptTypes: {
        receive: 'Receipt',
        transfer: 'Transfer',
        exit: 'Requisition'
      }
    },

    edit: {
      title: 'Edit Remittance'
    },

    receipt: {
      title: 'Remittance',
      dynamicTitle: '{name} Remittance',

      labels: {
        user: 'Account side',
        supplier: 'Supplier',
        orderId: 'Order No',
        price: 'Price',
        registryDate: 'Registration Date',
        orderDate: 'Order Date',
        phoneNumber: 'Phone Number',
        type: 'Type',
        status: 'Status',
        note: 'Note',
        signature: 'Signature',
        receiverName: 'Receiver Name',
        senderName: 'Sender Name',
        print: 'Print',
        back: 'Back'
      },

      prompts: {
        noteDialog: {
          title: 'Customer Invoice Note'
        }
      },

      table: {
        header: {
          rowNumber: 'row',
          image: 'Product Photo',
          code: 'barcode',
          name: 'Product Description',
          store: 'store',
          originStore: 'source warehouse',
          destinationStore: 'destination warehouse',
          count: 'count',
          unitPrice: 'unit amount',
          totalPrice: 'total amount'
        }
      },

      validators: {
        count: 'The number {name} contradicts the number of this product in the invoice',
        scan: '{name} not scanned'
      },

      confirmation: {
        delete: {
          title: 'Reference confirmation {name}',
          body: 'Are you sure about {name} the above remittance?'
        }
      },

      receiptTypes: {
        receive: 'Receipt',
        transfer: 'Transfer',
        exit: 'Requisition'
      },

      receiptActions: {
        receive: 'Received',
        transfer: 'Transfer',
        exit: 'Requisition'
      },

      status: {
        confirm: 'Confirmed',
        notConfirm: 'not approved'
      },

      postalLabel: {
        title: 'Postal label',
        dynamicTitle: 'Postal label sale invoice No. {number}',

        theme1: {
          orderNumber: 'Order No:',
          shipDate: 'Ship Date: ',
          customer: 'CUSTOMER: ',
          shipTo: 'SHIP TO: ',
          shipFrom: 'SHIP FROM: ',
          receiverInfo: 'SHIP TO INFO',
          senderInfo: 'SHIP FROM INFO',
          receiverName: 'Ship to name',
          senderName: 'Ship from name',
          address: 'Address',
          phoneNumber: 'Phone number',
          poBox: 'PO BOX '
        },

        theme2: {
          orderNumber: 'Order No: ',
          shipDate: 'Ship Date: ',
          customer: 'CUSTOMER: ',
          shipTo: 'SHIP TO: ',
          shipFrom: 'SHIP FROM: ',
          poBox: 'PO BOX ',
          logesticSupport: 'Logistics Support: ',
          customerService: 'Customer Service: '
        },

        actions: {
          print: 'Print',
          back: 'Back'
        }
      }
    },

    receiptConfirm: {
      table: {
        header: {
          rowNumber: 'row',
          barcode: 'Barcode',
          name: 'Kala',
          count: 'count',
          delete: 'Delete'
        }
      },

      validators: {
        notFound: 'No product found with this barcode',
        notExist: 'There is no product with this barcode in the invoice',
        noProductChoose: 'No products selected'
      },

      notifications: {
        insertProduct: {
          success: 'Number of items added'
        },

        dynamicInsertProduct: {
          success: 'Number { name } added'
        }
      }
    },

    validators: {
      store: 'Warehouse not selected!',
      originStore: 'Source warehouse not selected',
      destinationStore: 'Destination warehouse not selected',
      valuesExist: 'This value is in the list',
      productExist: '{name} is in list',
      noProductChoose: 'No product selected',
      barcode: 'No product found with this barcode',
      price: 'The amount of goods has not been imported!',
      productCount: 'Inventory is not enough!',
      dynamicProductCount: '{name} inventory is not enough',
      quantity: 'The number of entered products is not valid!'
    },

    notifications: {
      insert: {
        success: 'Anbar remittance registered successfully',
        error: 'Anbar remittance registration was accompanied by an error!'
      },

      confirm: {
        success: 'Warehouse transfer confirmed successfully',
        error: 'Confirmation of warehouse transfer was accompanied by an error'
      }
    }
  },

  received: {
    title: 'Receipt'
  },

  transferred: {
    title: 'Transferred'
  },

  sended: {
    title: 'Requisition'
  }
}
