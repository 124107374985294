export default {
  title: 'Accounting',

  types: {
    title: 'Chart of Accounts',

    labels: {
      name: 'Header Title'
    },

    accountsLabel: {
      asset: 'Asset',
      current_assets: 'Current assets',
      cash_and_bank_assets: 'Cash and bank',
      cash: 'Cash',
      bank: 'Bank',
      payment_gateways: 'Payment gateways',
      internet_payment_gateway: 'Internet payment gateway',
      pos: 'POS',
      on_way_inventory: 'On way',
      warehouses_inventory: 'Warehouses',
      waiting_to_send_inventory: 'Waiting to send',
      accounts_receivable: 'Accounts receivable',
      inventory: 'Inventory',
      liability: 'Liability',
      current_liabilities: 'Current liabilities',
      accounts_payable: 'Accounts payable',
      tax_payable: 'Tax payable',
      vat: 'Vat',
      taxes: 'Taxes',
      sale_tax: 'Sale tax',
      purchase_tax: 'Purchase tax',
      equity: 'Equity',
      capital: 'Capital',
      opening_capital: 'Opening capital',
      revenue: 'Revenue',
      other_revenue: 'Other revenue',
      service_revenue: 'Service',
      sale_revenue: 'Sale',
      personal_sale_revenue: 'Personal',
      total_personal_sale_revenue: 'Total',
      personal_return_sale_expenses: 'Refunds',
      personal_sale_discounts_expenses: 'Discounts',
      internet_sale_revenue: 'Internet',
      total_internet_sale_revenue: 'Total',
      internet_return_sale_expenses: 'Refunds',
      internet_sale_discounts_expenses: 'Discounts',
      telephone_sale_revenue: 'Telephone',
      total_telephone_sale_revenue: 'Total',
      telephone_return_sale_expenses: 'Refunds',
      telephone_sale_discounts_expenses: 'Discounts',
      expenses: 'Expenses',
      other_expenses: 'Other expenses',
      cancel_service_expenses: 'Cancel service',
      personnel_expenses: 'Personnel',
      payroll_expenses: 'Payroll',
      sale_expenses: 'Sale',
      sale_shipping_expenses: 'Shipping',
      purchase_expenses: 'Purchase',
      purchase_shipping_expenses: 'Shipping',
      financial_expenses: 'Financial',
      wage_expenses: 'Wage',
      bank_wage_expenses: 'Bank',
      payment_gateway_wage_expenses: 'Payment gateway',
      purchase: 'Purchase',
      total_purchase: 'Total',
      return_purchase_expenses: 'Refunds',
      purchase_discounts_expenses: 'Discounts',
      cost_of_goods_bought: 'Cost of goods bought',
      cost_of_goods_sold: 'Cost of goods sold'
    },

    notifications: {
      insert: {
        success: 'The title was successfully created',
        error: 'Logging new topic information failed'
      },

      edit: {
        success: 'The title was successfully created',
        error: 'Update header information failed'
      },

      delete: {
        success: 'Heading deleted successfully',
        error: 'Deleting a topic with an error'
      },

      parseError: {
        parent: 'Parent heading not selected',
        name: 'Heading title not entered'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete headline',
        body: 'Are you sure you want to delete the heading {name}?'
      }
    },

    insert: {
      title: 'Add new topic'
    },

    edit: {
      title: 'Edit Heading'
    }
  },

  openingBalance: {
    title: 'Opening Balance Sheet',

    labels: {
      assets: 'Assets',
      liabilities: 'Liabilities',
      equity: 'Capital'
    },

    accountsLabel: {
      cash_and_bank_assets: 'Cash and bank assets',
      bank: 'Bank',
      cash: 'Cash',
      warehouse_inventory: 'Inventory',
      accounts_receivable: 'Accounts receivable',
      accounts_payable: 'Accounts payable',
      capital: 'Capital'
    },

    notifications: {
      locked: 'is the first period of the lock',
      unLocked: 'The first period is not locked',
      lock: {
        success: 'Balance sheet successfully locked',
        error: 'Balance sheet error encountered'
      },
      unlock: {
        success: 'Balance sheet successfully opened',
        error: 'Balance sheet opening encountered an error'
      }
    },

    confirmations: {
      unlock: {
        title: 'Confirmation of opening balance',
        body: 'Are you sure you want to open the opening level?'
      }
    }
  },

  documents: {
    title: 'Documents',

    notifications: {
      delete: {
        success: 'Document information successfully deleted',
        error: 'Deleting document information successfully deleted'
      }
    },

    document: {
      title: 'سند',

      table: {
        header: {
          rowNumber: 'row',
          description: 'description',
          debt: 'debtor',
          credit: 'creditor'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'Receipt of expenses',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words) : ',
          account: 'To the account ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      receive: {
        receiveReceipt: 'The receipt of funds',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',
        transactionId: 'Transaction number:',
        paymentConfirm: 'Payment confirmation:',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'from mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'received'
        }
      },

      payment: {
        paymentReceipt: 'Receipt of payment',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'to mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'Treasury transfer receipt',
        date: 'date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payee: 'to ',
          payed: 'Was paid'
        }
      }
    },

    list: {
      title: 'Documents',

      table: {
        header: {
          id: 'Document number',
          createdAt: 'Date',
          creator: 'creator',
          description: 'Documents',
          type: 'type'
        },

        filters: {
          type: {
            all: 'All'
          }
        }
      },

      confirmation: {
        deleteDocument: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      types: {
        manual: 'Manual',
        receive: 'Receive',
        payment: 'Payment',
        withdrawal: 'Withdrawal',
        internal_fund_transfer: 'Transfer',
        revenue: 'Revenue',
        cost: 'Cost',
        beginning_inventory: 'Beginning Inventory',
        payroll: 'Payroll',
        purchase: 'Purchase',
        return_purchase: 'Return Purchase',
        sale: 'Sale',
        return_sale: 'Return Sale',
        warehouse_receipt: 'Warehouse Receipt',
        warehouse_requisition: 'Warehouse Requisition',
        inventory_transfer: 'Inventory Transfer'
      }
    },

    insert: {
      title: 'Add Document',
      dynamicTitle: 'Add Receipt / Payment Document',

      labels: {
        documentType: 'document type',
        receive: 'receive',
        payment: 'payment',
        paymentTo: 'payment to',
        receiveFrom: 'receive from',
        withdrawalFrom: 'withdrawal from',
        depositTo: 'deposit to',
        price: 'Amount',
        cashBox: 'cashier',
        bank: 'bank',
        depositType: 'Deposit type',
        choose: 'choose',
        pos: 'POS',
        cardToCard: 'card to card',
        shaba: 'شبا'
      },

      notifications: {

        receive: {
          insert: {
            success: 'Receipt document created successfully',
            error: 'Creating a receive document with an error'
          }
        },

        payment: {
          insert: {
            success: 'Payment document created successfully',
            error: 'Creating a payment document with an error'
          }
        },

        parseError: {
          user: 'User not selected',
          cashBox: 'Cash is not selected',
          bank: 'Bank not selected',
          price: 'Amount not entered',
          depositTo: 'Deposit to bank or fund is not specified',
          depositType: 'Deposit type not specified',
          withdrawalFrom: 'Withdrawal from bank or fund not specified'
        }
      }
    }
  }
}
