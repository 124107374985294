export default {
  title: 'کالا و خدمت',

  list: {
    title: 'لیست کالا',

    table: {
      header: {
        row: 'ردیف',
        name: 'عنوان محصول',
        shopName: 'نام نمایشی در فروشگاه',
        code: 'کد محصول',
        barcode: 'بارکد',
        price: 'قیمت',
        image: 'عکس محصول',
        category: 'دسته بندی',
        creator: 'ایجاد کننده',
        type: 'نوع',
        status: 'وضعیت',
        shops: 'فروشگاه ها',
        lastModifiedDate: 'تاریخ بروزرسانی'
      },

      filters: {
        status: {
          all: 'همه',
          notConnect: 'عدم اتصال',
          draft: 'پیش نویس',
          waiting: 'در انتظار',
          published: 'منتشر شده',
          personal: 'خصوصی'
        },

        type: {
          all: 'همه',
          simpleProduct: 'کالای ساده',
          variableProduct: 'کالای متغیر'
        }
      }
    }
  },

  insert: {
    title: 'افزودن کالا',

    labels: {
      importData: 'آپلود اول دوره کالا'
    },

    types: {
      label: 'انتخاب نوع افزودن',
      useral: 'افزودن به یوزرال',
      woocommerce: 'افزودن به {shopName} و یوزرال'
    },

    table: {
      header: {
        inventory: 'موجودی انبار',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        delete: 'حذف'
      }
    },

    description: {
      title: 'توضیحات',

      useralDescription: 'اطلاعات این محصول فقط در سامانه یوزرال ثبت میشود.',
      woocommerceDescription: 'اطلاعات محصول به صورت مستقیم در فروشگاه شما در وضعیت در حال بازبینی ثبت میشود.',
      notCategorySelected: 'هیچ دسته بندی انتخاب نشده است',
      noAttributesExistForCategory: 'هیچ ویژگی برای این دسته بندی وجود ندارد',
      noAttributeChooseForVariables: 'هیچ ویژگی به عنوان متغیر انتخاب نشده است',
      inventory: 'در این صفحه شما می توانید موجودی کل محصول را وارد کنید. در صورتی که تعداد موجودی برابر با صفر باشد، محصول ناموجود در نظر گرفته خواهد شد.',
      attribute: 'شما می توانید ویژگی مورد نظر خود را انتخاب و به لیست ویژگی های محصول اضافه نمایید. در صورتی که محصول از نوع متغیر باشد، شما میتوانید <b>یکی</b> از ویژگی ها را به عنوان ویژگی متغیر محصول انتخاب نمایید'
    },

    notifications: {
      type: {
        notSelected: {
          error: 'نوع افزودن انتخاب نشده است'
        }
      },
      notConnectToServer: {
        error: 'به علت عدم اتصال به سایت، قادر به افزودن محصول نمی باشید'
      },
      imageUpload: {
        success: 'عکس با موفقیت آپلود شد'
      },
      imageRemove: {
        success: 'لوگو با موفقیت حذف شد'
      },
      insertProduct: {
        success: 'اطلاعات کالا با موفقیت ثبت شد',
        error: 'ثبت اطلاعات کالا با خطا مواجه شد'
      },
      woocommerceInsertProduct: {
        success: 'اطلاعات کالا با موفقیت  در فروشگاه ثبت شد',
        error: 'ثبت اطلاعات کالا با خطا مواجه شد'
      }
    },

    accordionList: {
      brand: {
        title: 'برند مورد نظر را انتخاب نمایید',

        insert: {
          title: 'افزودن برند جدید',
          brand: 'عنوان برند'
        },

        edit: {
          title: 'ویرایش برند',
          category: 'عنوان برند'
        },

        delete: {
          title: 'حذف برند',
          message: 'با حذف این برند، تمامی اطلاعات مرتبط با آن نیز حذف می شود.'
        }
      },

      category: {
        title: 'دسته بندی مورد نظر را انتخاب نمایید',

        insert: {
          title: 'افزودن دسته بندی جدید',
          category: 'عنوان دسته بندی'
        },

        edit: {
          title: 'ویرایش دسته بندی',
          category: 'عنوان دسته بندی'
        },

        delete: {
          title: 'حذف دسته بندی',
          message: 'با حذف این دسته بندی، تمامی اطلاعات مرتبط با آن نیز حذف می شود.'
        }
      },


      attributes: 'ویژگی ها',
      insertAttribute: {
        title: 'افزودن ویژگی جدید',
        attribute: 'عنوان ویژگی',
        values: 'مقادیر'
      },
      editAttribute: {
        title: 'ویرایش ویژگی',
        attribute: 'عنوان ویژگی',
        values: 'مقادیر'
      },

      buttons: {
        accept: 'تائید',
        cancel: 'انصراف'
      },

      alert: {
        title: 'پیغام',
        brandCreated: 'برند با موفقیت ایجاد شد',
        brandUpdated: 'برند با موفقیت ویرایش شد',
        brandDeleted: 'برند با موفقیت حذف شد',
        categoryCreated: 'دسته بندی با موفقیت ایجاد شد',
        categoryUpdated: 'دسته بندی با موفقیت ویرایش شد',
        categoryDeleted: 'دسته بندی با موفقیت حذف شد',
        errorTitle: 'خطا',
        error: 'فرآیند با خطا همراه شد'
      }
    }
  },

  edit: {
    title: 'ویرایش کالا',
    dynamicTitle: 'ویرایش {name}',

    table: {
      header: {
        inventory: 'موجودی انبار',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        delete: 'حذف'
      }
    },

    description: {
      title: 'توضیحات',

      variableDescription: 'شما می توانید از طریق این صفحه، اطلاعات مربوط به هر متغیر را در فروشگاه خود ویرایش کنید.',
      useralDescription: 'اطلاعات این محصول فقط در سامانه یوزرال ثبت میشود.',
      woocommerceDescription: 'اطلاعات محصول به صورت مستقیم در فروشگاه شما در وضعیت در حال بازبینی ثبت میشود.',
      notCategorySelected: 'هیچ دسته بندی انتخاب نشده است',
      noAttributesExist: 'هیچ ویژگی برای این دسته بندی وجود ندارد',
      noAttributeChooseForVariables: 'هیچ ویژگی به عنوان متغیر انتخاب نشده است',
      inventory: 'در این صفحه شما می توانید موجودی کل محصول را وارد کنید. در صورتی که تعداد موجودی برابر با صفر باشد، محصول ناموجود در نظر گرفته خواهد شد.',
      attribute: 'شما می توانید ویژگی مورد نظر خود را انتخاب و به لیست ویژگی های محصول اضافه نمایید. در صورتی که محصول از نوع متغیر باشد، شما میتوانید <b>یکی</b> از ویژگی ها را به عنوان ویژگی متغیر محصول انتخاب نمایید'
    },

    notifications: {
      type: {
        notSelected: {
          error: 'نوع افزودن انتخاب نشده است'
        }
      },
      notConnectToServer: {
        error: 'به علت عدم اتصال به سایت، قادر به افزودن محصول نمی باشید'
      },
      imageUpload: {
        success: 'عکس با موفقیت آپلود شد'
      },
      imageRemove: {
        success: 'لوگو با موفقیت حذف شد'
      },
      editProduct: {
        success: 'اطلاعات کالا با موفقیت ثبت شد',
        error: 'ثبت اطلاعات کالا با خطا مواجه شد'
      },
      woocommerceEditProduct: {
        success: 'اطلاعات کالا با موفقیت  در فروشگاه ثبت شد',
        error: 'ثبت اطلاعات کالا با خطا مواجه شد'
      },
      deleteVariant: {
        success: 'اطلاعات متغیر با موفقیت حذف شد',
        error: 'حذف اطلاعات متغیر با خطا همراه شد'
      },
      deleteProduct: {
        success: 'اطلاعات کالا با موفقیت حذف شد',
        error: 'حذف اطلاعات کالا با خطا همراه شد'
      }
    },

    confirmations: {
      removeProduct: {
        title: 'تائیدیه',
        body: 'آیا از حذف این کالا اطمینان دارید؟'
      },
      removeWoocommerceProduct: {
        title: 'تائیدیه',
        body: 'آیا از حذف این کالا اطمینان دارید؟ در صورت تائید، اطلاعات محصول از فروشگاه شما نیز حذف می شود!'
      },
      changeProductType: {
        title: 'هشدار',
        body: 'در صورت تغییر نوع محصول متغیر به ساده، اطلاعات تمامی متغیرها پاک می شود'
      },
      changeVariantAttribute: {
        title: 'هشدار',
        body: 'در صورت تغییر ویژگی انتخاب شده برای متغیر، اطلاعات تمامی متغیرهای ساخته شده پاک می شوند. آیا از ادامه فرایند اطمینان دارید؟'
      },
      deleteVariable: {
        title: 'تائیدیه',
        body: 'آیا از حذف این متغیر اطمینان دارید؟ در صورت تائید، اطلاعات محصول از فروشگاه شما نیز حذف می شود!'
      }
    }
  },

  product: {
    title: 'کالا',

    labels: {
      withoutPrice: 'بدون قیمت',
      rangePrice: 'از {min} تا {max}'
    },

    importantData: {
      price: 'قیمت',
      salePrice: 'قیمت حراج',
      category: 'دسته بندی',
      type: 'نوع'
    },

    actions: {
      barcode: 'بارکد کالا',
      saleTurnover: 'گردش فروش کالا',
      attributes: 'ویژگی های محصول',
      variants: 'متغیر های محصول'
    },


    attributes: {
      title: 'ویژگی های کالا',
      dynamicTitle: 'ویژگی های {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          key: 'عنوان ویژگی',
          value: 'مقدار ویژگی'
        }
      }
    },

    barcode: {
      title: 'بارکد کالا',
      dynamicTitle: 'بارکد {name}',

      labels: {
        barcodeType: 'نوع بارکد',
        barcodeValue: 'مقدار بارکد',
        barcode: 'بارکد',
        QRCode: 'QRCode',
        siteLink: 'آدرس فروشگاه',
        barcodeLink: 'بارکد کالا',
        hasImage: 'نمایش عکس',
        cardSize: 'اندازه کارت',
        cardPieces: '{count} تایی'
      }
    },

    turnover: {
      sale: {
        title: 'گردش فروش کالا',
        dynamicTitle: 'گردش فروش {name}',
      },

      storeroom: {
        title: 'گردش انبار کالا',
        dynamicTitle: 'گردش انبار {name}',
      },

      table: {
        header: {
          documentId: 'شماره حواله',
          invoiceId: 'شماره فاکتور',
          date: 'تاریخ',
          destination: 'طرف حساب',
          unitPrice: 'مبلغ واحد',
          quantity: 'تعداد',
          inventory: 'موجودی'
        }
      },

      labels: {
        dynamicStoreReceipt: 'حواله انبار {id}'
      },

      typeMapper: {
        openingBalance: 'اول دوره',
        receive: 'رسید انبار',
        exit: 'خروج انبار',
        transfer: 'انتقالی'
      },

      statusMapper: {
        sent: 'ارسال شده',
        onWay: 'در راه',
        received: 'رسیده به انبار',
        waitingToSend: 'در انتظار ارسال'
      }
    },

    inventory: {
      title: 'موجودی کالا',
      dynamicTitle: 'موجودی کالا {name}',

      table: {
        header: {
          rowNumber: 'ردیف',
          storeroom: 'انبار',
          openingInventory: 'اول دوره',
          received: 'رسیده به انبار',
          sent: 'ارسال شده',
          minInventory: 'حداقل موجودی',
          onWay: 'در راه',
          totalInventory: 'موجودی کل',
          waitingToSend: 'در انتظار ارسال',
          saleInventory: 'موجودی قابل فروش'
        }
      }
    },

    variants: {
      title: 'متغیر های کالا',
      dynamicTitle: 'متغیر های {name}',

      table: {
        header: {
          barcode: 'بارکد',
          price: 'قیمت',
          salePrice: 'قیمت حراج'
        }
      }
    },


    type: {
      simple: 'کالای ساده',
      variant: 'کالای متغیر',
      service: 'خدمت'
    }
  },

  select: {
    title: 'لیست کالاها',

    table: {
      header: {
        select: 'انتخاب',
        rowNumber: 'ردیف',
        name: 'عنوان محصول',
        code: 'کد',
        barcode: 'بارکد',
        price: 'قیمت',
        image: 'عکس',
        category: 'دسته بندی',
        type: 'نوع',
        variant: 'متغیر',
        salePrice: 'قیمت حراج',
        purchasePrice: 'میانگین قیمت خرید',
        quantity: 'موجودی',
        storeroom: 'انبار',
        edit: 'ویرایش'
      },

      filters: {
        type: {
          all: 'همه',
          simple: 'کالای ساده',
          variant: 'کالای متغیر'
        }
      }
    }
  },

  trash: {
    title: 'سطل آشغال کالا و خدمت',

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'عنوان محصول',
        code: 'کد محصول',
        image: 'عکس محصول',
        type: 'نوع',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف کالا',
        body: 'آیا از حذف دایم {name} اطمینان دارید؟'
      }
    },

    notifications: {
      restore: {
        success: 'کالا با موفقیت بازگردانده شد',
        error: 'بازگردانی کالا با خطا همراه شد'
      },
      delete: {
        success: 'کالا با موفقیت حذف شد',
        error: 'حذف کالا با خطا همراه شد'
      }
    }
  },

  openingBalance: {
    productList: 'لیست کالاها',

    insert: {
      title: 'افزودن اول دوره انبار'
    },


    productType: {
      simple: 'کالای ساده',
      variant: 'کالای متغیر',
      service: 'خدمت'
    },

    labels: {
      count: 'تعداد',
      price: 'قیمت',
      delete: 'حذف اول دوره',
      insertProduct: 'افزودن کالای جدید',
      importData: 'آپلود فایل اول دوره کالاها'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        barcode: 'بارکد',
        name: 'کالا',
        variant: 'متغیر',
        count: 'تعداد',
        unitPrice: 'مبلغ واحد',
        totalPrice: 'مبلغ کل',
        delete: 'حذف'
      }
    },

    validators: {
      valueExist: 'این مقدار در لیست وجود دارد',
      productExist: '{name} در لیست وجود دارد',
      barcode: 'کالایی با این بارکد موجود نمی باشد و یا در اول دوره وجود دارد',
      lock: 'اول دوره قفل است'
    },

    notifications: {
      insert: {
        success: 'اول دوره انبار با موفقیت ثبت شد',
        error: 'ثبت اول دوره انبار با خطا همراه شد!'
      },
      importData: {
        success: 'آپلود فایل اول دوره با موفقیت انجام شد',
        error: 'درخواست آپلود اول دوره با خطا همراه شد'
      },
      edit: {
        success: 'اول دوره کالای مورد نظر با موفقیت بروزرسانی شد',
        error: 'بروزرسانی اول دوره کالای مورد نظر با خطا همراه شد'
      },
      delete: {
        success: 'اول دوره کالای مورد نظر با موفقیت حذف شد',
        error: 'حذف اول دوره کالای مورد نظر با خطا همراه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف',
        body: 'آیا از حذف اول دوره کالای مورد نظر اطمینان دارید؟'
      }
    }
  },

  labels: {
    type: 'نوع کالا',
    id: 'شناسه',
    barcode: 'بارکد',
    productName: 'نام کالا',
    serviceName: 'نام خدمت',
    productShopName: 'نام نمایشی در فروشگاه',
    category: 'دسته بندی',
    serviceCategory: 'دسته بندی خدمات',
    price: 'قیمت',
    purchasePrice: 'میانگین قیمت خرید',
    productPrice: 'قیمت کالا',
    servicePrice: 'قیمت خدمت',
    salePrice: 'قیمت حراج',
    setSalePriceRange: 'تعیین بازه حراج',
    saleStartDate: 'تاریخ شروع حراج',
    saleEndDate: 'تاریخ پایان حراج',
    phaseOut: 'توقف تولید',
    description: 'توضیحات',
    inventory: 'موجودی انبار',
    chooseAttribute: 'انتخاب ویژگی',
    chooseCategory: 'انتخاب دسته بندی',
    withoutCategory: 'بدون دسته بندی',
    removeProduct: 'حذف کالا',
    createVariable: 'ایجاد متغیر جدید',
    removeVariable: 'حذف متغیر'
  },

  validators: {
    dateHelp: 'ورودی باید به صورت {date} وارد شود.',
    wrongInputValues: 'اطلاعات وارد شده صحیح نمی باشد',
    saleDateRange: 'بازه شروع و پایان حراج به صورت صحیح وارد نشده است',
    imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
    fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
    variantExist: 'متغیری با این ویژگی وجود دارد',
    variantListTotalCount: 'تعداد کل حالت های ممکن برابر است با {max}',
    price: 'فیلد قیمت الزامی است',
    salePrice: 'قیمت حراج نمی تواند از قیمت اصلی بیشتر یا مساوی باشد',
    variableSalePrice: 'قیمت حراج متغیر با بارکد {barcode} نمی تواند از قیمت اصلی بیشتر یا مساوی باشد',
    code: 'کالایی با این کد، وجود دارد و یا کد اشتباه می باشد',
    barcode: 'کالایی با این بارکد، وجود دارد و یا بارکد اشتباه می باشد',
    variableBarcode: 'بارکد متغیر ردیف {row} تکراری است',
    title: 'کالایی با این نام وجود دارد و یا نام به صورت صحیح وارد نشده است',
    saleStartDate: 'تاریخ شروع حراج به صورت صحیح وارد نشده است.',
    saleEndDate: 'تاریخ پایان حراج به صورت صحیح وارد نشده است.'
  },

  dataCategory: {
    generalInfo: 'اطلاعات عمومی',
    attributes: 'ویژگی ها',
    variables: 'متغیرها'
  },

  productType: {
    simple: 'ساده',
    variant: 'متغیر',
    service: 'خدمت'
  },

  pricing: {
    title: 'قیمت گذاری محصولات',

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'عنوان محصول',
        code: 'کد محصول',
        barcode: 'بارکد',
        price: 'قیمت',
        salePrice: 'قیمت حراج',
        purchasePrice: 'میانگین قیمت خرید',
        image: 'عکس محصول',
        category: 'دسته بندی',
        variant: 'متغیر',
        creator: 'ایجاد کننده',
        type: 'نوع',
        status: 'وضعیت',
        shops: 'فروشگاه ها',
        lastModifiedDate: 'تاریخ بروزرسانی'
      },

      filters: {
        type: {
          all: 'همه',
          simpleProduct: 'کالای ساده',
          variableProduct: 'کالای متغیر'
        }
      }
    },

    notifications: {
      edit: {
        success: 'قیمت های کالای مورد نظر با موفقیت بروز شد',
        error: 'بروزرسانی قیمت های کالای مورد نظر با خطا همراه شد'
      }
    },

    validators: {
      salePrice: 'قیمت حراج نمی تواند از قیمت اصلی بیشتر یا مساوی باشد'
    },

    status: {
      notConnect: 'عدم اتصال',
      draft: 'پیش نویس',
      waiting: 'در انتظار',
      published: 'منتشر شده',
      personal: 'خصوصی'
    }
  },

  brands: {
    title: 'برند ها'
  }
}
